import React from "react";
import PropTypes from 'prop-types';
import { Container, Row, Col } from "react-bootstrap";
import { graphql, StaticQuery } from 'gatsby'

import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";
import SEO from "../../components/SEO";

const BlogList = ({data}) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <>
      {/* <!-- Blog section --> */}
      <Section className="position-relative">
        <SEO title="Instant Demo | Blog" />
        <Container>
          <Row className="justify-content-center">
          {posts && posts.map(({ node: post }) => {
            return (
              <Col lg="4" className="mb-8" key={post.id}>
                <PostCard
                  img={post.frontmatter.featuredimage}
                  preTitle={post.frontmatter.date}
                  title={post.frontmatter.title}
                  postPath={`/blog${post.fields.slug}`}
                  readingTime={post.fields.readingTime.text}
                >
                  {post.frontmatter.description}
                </PostCard>
              </Col>
            )
          })}
          </Row>
        </Container>
      </Section>
    </>
  )
}

BlogList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
                readingTime {
                  text
                }
              }
              frontmatter {
                title
                templateKey
                description
                date(formatString: "MMM DD")
                featuredimage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogList data={data} count={count} />}
  />
)
