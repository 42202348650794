import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { Link } from "gatsby";
import { Title, Box, Text, Span } from "../Core";
import { device } from "../../utils";
import Img from "gatsby-image"

const Card = styled(Box)`
  border-radius: 10px 10px;
  border: 1px solid #eae9f2;
  transition: 0.4s;
  overflow: hidden;

  &:hover {
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  }
`;

const ImageContainerHorizontal = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;

  @media ${device.md} {
    width: 100%;
    min-width: 350px;
    max-width: 350px;
  }
  @media ${device.lg} {
    min-width: 265px;
  }
  @media ${device.xl} {
    min-width: 350px;
    max-width: 350px;
  }
`;

const CardText = styled(Box)`
  padding: 30px;
`;

const TitleStyled = styled(Title)`
  transition: 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const PostCard = ({
  horizontal = false,
  img,
  preTitle,
  title,
  children,
  postPath,
  readingTime,
  ...rest
}) => (
  <Card
    className={horizontal ? "d-flex flex-column flex-md-row" : ""}
    {...rest}
  >
    {horizontal ? (
      <ImageContainerHorizontal>
        <Link to={postPath} className="w-100 h-100 d-flex">
          <Img
            className="w-100 img-fluid"
            fluid={img.childImageSharp.fluid}
            alt="Featued Image"
          />
        </Link>
      </ImageContainerHorizontal>
    ) : (
      <Box className="position-relative">
        <Link to={postPath} className="w-100 h-100 d-flex">
          <Img
            className="w-100 img-fluid"
            fluid={img.childImageSharp.fluid}
            alt="Featued Image"
          />
        </Link>
      </Box>
    )}

    <CardText>
      <Text fontSize={2} lineHeight={1.75} mb="14px">
        {preTitle} · {readingTime}
      </Text>

      <Link to={postPath}>
        <TitleStyled variant="card" mb="14px">
          {title}
        </TitleStyled>
      </Link>
      <Text fontSize={2} lineHeight={1.75} mb="16px">
        {children}
      </Text>
      <Box>
        <Link to={postPath}>
          <Span color="primary">Continue Reading</Span>
        </Link>
      </Box>
    </CardText>
  </Card>
);

PostCard.propTypes = {
  horizontal: PropTypes.bool,
  img: PropTypes.object,
  preTitle: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  postPath: PropTypes.string,
  readingTime: PropTypes.string
}

export default PostCard;
