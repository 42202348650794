import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title } from "../components/Core";

import BlogList from "../sections/blog/BlogList";

const Blog = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          isFluid: true,
        }}
      >
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title variant="hero">Our Blog</Title>
              </Col>
            </Row>
          </Container>
        </Section>
        <BlogList />
      </PageWrapper>
    </>
  );
};

export default Blog;